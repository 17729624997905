<template>
	<section>
		<b-row class="mb-2">
		    <b-col>
				<Breadcrumb :labels="labels" />				
		    </b-col>		    
		</b-row>		
		<b-row>
		    <b-col md="12">
		    	<div class="relative">
			        <b-tabs card class="caption-add table-custom">                       
			            <b-tab title="Basic Info" active class="overview-tab">	
			            	<validation-observer
					          	ref="form_rel"
					          	#default="{invalid}" >
				                <b-form @submit.prevent class="profile-frm mb-20 hasshadow" style="border-radius: 5px">
				                	<b-row class="align-center">
							            <b-col>
							            	<div class="text-right mb-2">
							                    <b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelAdd" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>

							                    <template v-if="(userData && permission.create)">
								                    <b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveAdd">
								                        Save
								                    </b-button>
								                </template>
								                <template v-else>
								                	 <b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
								                        Save
								                    </b-button>
								                </template>
							                </div>
							            </b-col>
							        </b-row>				                
					                <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
					                <div class="frm-basic size-16 black-child d-block">              
				                      	<b-row style="width: 100%; margin: 0;">   
				                          	<b-col lg="6" class="px-0">             
				                              	<b-form-group label="PPID" label-cols-lg="4" class="star-required input-group-label">
				                                    <b-form-input
				                                      placeholder="PPID (Autofill)"
				                                      readonly
				                                      disabled
				                                    ></b-form-input>
				                              	</b-form-group>
				                          	</b-col>	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Status" label-cols-lg="4" class="star-required">	
					                            	<validation-provider
						                                #default="{ errors }"
						                                name="Status"
						                                rules="required"
						                            >
						                                <v-select
						                                	v-model="selectedStatus"
						                                    label="title"
						                                    :options="selectStatus"
						                                    placeholder="Select Status"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col> 	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Salutation" label-cols-lg="4" >	
					                            	<validation-provider
						                                #default="{ errors }"
						                                name="Salutation"
						                            >
						                                <v-select
						                                	v-model="selectedSalutation"
						                                    label="title"
						                                    :options="salutation"
						                                    placeholder="Select Salutation"
					                                    />

					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col> 	
				                          	<b-col lg="6" class="px-0">             
				                              	<b-form-group label="Contact No." label-cols-lg="4" class="input-group-label">
				                              		<validation-provider
						                                #default="{ errors }"
						                                name="Contact No."
						                            >
					                                    <b-form-input
					                                    	v-model="form.phone"
					                                      	placeholder="Contact No."
					                                      	type="number"
					                                      	:formatter="length20"
															@keypress="validateNumber"
					                                    ></b-form-input>
														<small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                          	</b-col>
				                          	<b-col lg="6" class="px-0">             
				                              	<b-form-group label="First Name" label-cols-lg="4" class="star-required input-group-label">
				                              		<validation-provider
						                                #default="{ errors }"
						                                name="First Name"
						                                rules="required"
						                            >
					                                    <b-form-input
					                                    	v-model="form.f_name"
					                                      	placeholder="First Name"
					                                      	:formatter="length50"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                          	</b-col>
				                          	<b-col lg="6" class="px-0">             
				                              	<b-form-group label="Last Name" label-cols-lg="4" class="star-required input-group-label">
				                              		<validation-provider
						                                #default="{ errors }"
						                                name="Last Name"
						                                rules="required"
						                            >
					                                    <b-form-input
					                                    	v-model="form.l_name"
					                                      	placeholder="Last Name"
					                                      	:formatter="length50"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                          	</b-col>
				                          	<b-col lg="12" class="px-0">             
				                              	<b-form-group label="Full Name" label-cols-lg="2" class="star-required input-group-label">
				                                    <b-form-input
				                                    	v-model="form.full_name"
				                                      	placeholder="Full name"
				                                      	:formatter="length50"
				                                      	disabled
				                                    ></b-form-input>
				                              	</b-form-group>
				                          	</b-col>
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Gender" label-cols-lg="4">	
					                            	<validation-provider
						                                #default="{ errors }"
						                                name="Gender"
						                            >
						                                <v-select
						                                	v-model="selectedGender"
						                                    label="title"
						                                    :options="gender"
						                                    placeholder="Select Gender"
					                                    />
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col> 
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Date of Birth" label-cols-lg="4">	
					                            	<div class="calendarIcon">
														<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
													</div>
													<flat-pickr
														v-model="form.date"
														class="form-control fieldCalendar"           
														:config="{ altInput: true,altFormat: 'd/m/Y', dateFormat: 'Y-m-d', maxDate: maxBirthdayFun }"
														placeholder="Select a date"
													/>
					                            </b-form-group>
				                          	</b-col> 
				                          	<b-col lg="12" class="px-0">             
				                              	<b-form-group label="Email" label-cols-lg="2" class="star-required input-group-label">
				                              		<validation-provider
						                                #default="{ errors }"
						                                name="Email"
						                                rules="required|email"     
						                            >
					                                    <b-form-input
					                                    	type = "email"
					                                    	v-model="form.email"
					                                      	placeholder="Email"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                              	<div style="border-top: 1px solid #B1B1B1; height: 20px; "></div>
				                          	</b-col>
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Mailing Country" label-cols-lg="4" >
					                            	<validation-provider
						                                #default="{ errors }"
						                                name="Mailing Country"
						                            >
						                                <v-select
						                                	v-model="selectedCountry"
						                                    label="name"
						                                    :options="selectCountry"
						                                    placeholder="Select Country"
					                                    />
														<small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col>
				                          	<b-col lg="12" class="px-0">             
				                              	<b-form-group label="Organisation" label-cols-lg="2" class="star-required">
				                              		<validation-provider
						                                #default="{ errors }"
						                                name="Organisation"
														rules="required"
						                            >
					                                    <v-select
					                                    	v-model="selectedOr"
						                                    label="name"
						                                    :options="selectOr"
						                                    placeholder="Select Organisation"
					                                    />
														<small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                          	</b-col>
				                          	<b-col lg="12" class="px-0">             
				                              	<b-form-group label="Designation/Position" label-cols-lg="2" class="input-group-label" label-class="pl-3">
				                              		<validation-provider
						                                #default="{ errors }"
						                                name="Designation / Position"
						                            >
					                                    <b-form-input
					                                    	v-model="form.designation"
					                                      	placeholder="Designation"
					                                      	:formatter="length50"
					                                    ></b-form-input>
														<small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                          	</b-col>
				                          	<b-col lg="12" class="px-0">             
				                              	<b-form-group label="Mailing Street" label-cols-lg="2" class="input-group-label">
				                              		<validation-provider
						                                #default="{ errors }"
						                                name="Mailing Street"
						                            >
					                                    <b-form-input
					                                    	v-model="form.street"
					                                      	placeholder="Mailing Postal Code"
					                                      	:formatter="length250"
					                                    ></b-form-input>
														<small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                          	</b-col>
				                          	<b-col lg="12" class="px-0">             
				                              	<b-form-group label="Mailing Zip/Postal Code" label-cols-lg="2" class="input-group-label" label-class="pl-3">
				                              		<validation-provider
						                                #default="{ errors }"
						                                name="Mailing Zip / Postal Code"
						                            >
					                                    <b-form-input
					                                    	v-model="form.code"
					                                      	placeholder="Postal Code"
					                                      	:formatter="length20"
					                                    ></b-form-input>
														<small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                          	</b-col>
				                          	<b-col lg="12" class="px-0">             
				                              	<b-form-group label="Mailing Zip/Province" label-cols-lg="2" class="input-group-label" label-class="pl-3">
				                              		<validation-provider
						                                #default="{ errors }"
						                                name="Mailing Zip / Province"
						                            >
					                                    <b-form-input
					                                    	v-model="form.province"
					                                      	placeholder="Province"
					                                      	:formatter="length50"
					                                    ></b-form-input>
														<small class="text-danger">{{ errors[0] }}</small>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                          	</b-col>
				                          	<b-col lg="12" class="px-0">             
				                              	<b-form-group label="Mailing City" label-cols-lg="2" class="input-group-label">
				                              		<validation-provider
						                                #default="{ errors }"
						                                name="Mailing City"
						                            >
					                                    <b-form-input
					                                    	v-model="form.city"
					                                      	placeholder="City"
					                                      	:formatter="length50"
					                                    ></b-form-input>
														<small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                          	</b-col>
				                          	<b-col lg="12" class="px-0">             
				                              	<b-form-group label="Remarks" label-cols-lg="2">
				                                    <b-form-textarea
				                                    	v-model="form.remarks"
			                                          	placeholder="Remarks"
			                                          	rows="5"
			                                          	:formatter="length1000"
			                                        ></b-form-textarea>
				                              	</b-form-group>
				                          	</b-col>  
				                          	<b-col lg="12" class="px-0">
				                          		<div style="border-top: 1px solid #B1B1B1; height: 20px; "></div>  
				                          	</b-col>			                          	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Password" label-cols-lg="4" class="star-required input-group-label">
					                            	<validation-provider
						                                #default="{ errors }"
						                                rules="required|min:12"
						                                name="password"
						                                vid="password"
						                            >
						                                <b-form-input
						                                	v-model="form.password"
					                                      	placeholder="Password"
					                                      	type="password"
					                                      	:formatter="length50"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col> 	 
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Re-type Password" label-cols-lg="4" class="star-required input-group-label">
					                            	<validation-provider
						                                #default="{ errors }"
						                                name="Re-type Password"
						                                rules="required|confirmed:password"
						                            >
						                                <b-form-input
						                                	v-model="form.re_pass"
					                                      	placeholder="Retype Password"
					                                      	type="password"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col>             
				                      	</b-row>
					                </div>
					            </b-form> 
					        </validation-observer>
			            </b-tab>                    
			        </b-tabs>     
			    </div>
			</b-col>
		</b-row>
	</section>
</template>
<script>

	import Breadcrumb from '../partial/Breadcrumb'

	import flatPickr from 'vue-flatpickr-component'
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver, extend, Validator} from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	import globalAdmin from '../model/globalAdmin'

	extend('min', {
    ...min,
    message: 'At least 12 characters and contain characters from at least two (2) of the following four (4) categories: Upper case (A through Z), Lower case (a through z), Digits (0-9), Special characters (!,$,#,%, etc)'
	});
	export default {
	  	components: {
	  		Breadcrumb,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,
          	flatPickr
	  	},		
	  	mixins: [togglePasswordVisibility,globalAdmin],		
	  	watch: {
	  		'form.f_name'(val){
	  			if(val){
	  				if(this.form.l_name){
	  					this.form.full_name = this.form.l_name + ' ' + val
	  				}
	  				else{
	  					this.form.full_name = val
	  				}	  				
	  			}
	  			else{
	  				if(this.form.l_name){
	  					this.form.full_name = this.form.l_name
	  				}
	  				else{
	  					this.form.full_name = ''
	  				}	  	
	  			}
	  		},
	  		'form.l_name'(val){
	  			if(val){
	  				if(this.form.f_name){
	  					this.form.full_name = val + ' ' + this.form.f_name
	  				}
	  				else{
	  					this.form.full_name = val
	  				}
	  			}
	  			else{
	  				if(this.form.f_name){
	  					this.form.full_name = this.form.f_name
	  				}
	  				else{
	  					this.form.full_name = ''
	  				}
	  			}
	  		},
	  	},
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
				labels: { "staff-participant-add": "" },    
				permission: [],  
			    form: {
			    	phone: null,
			    	full_name: null,
			    	l_name: null,
			    	f_name: null,
			    	date: null,
			    	email: null,
			    	designation: null,
			    	street: null,
			    	code: null,
			    	province: null,
			    	city: null,
			    	remarks: null,
			    	password: null,
			    	re_pass: null,
			    },
			    selectStatus: [
			    	{title: 'Inactive', value: 0},
			    	{title: 'Active', value: 1},			    	
			    ],
			    selectedStatus: null,
			    salutation: [
                  { title: 'Mr', value: 0}, 
                  { title: 'Mrs', value: 1 },
                  { title: 'Miss', value: 2 },
                  { title: 'Ms', value: 3 },
				  { title: 'Sir', value: 4 },
				  { title: 'Dr', value: 5 },
                ],
                selectedSalutation: null,
                gender: [
                	{ title: 'Female', value: 0 },
                  	{ title: 'Male', value: 1 },                  
                ],
                selectedGender: null,
                selectCountry: [],
                selectedCountry: null,
                selectOr: [],
                selectedOr: null,                
            }
		},		
		computed: {
          	maxBirthdayFun(){            
              	var d = new Date()
              	d.setDate(d.getDate() - 6205)
              	return d
          	},
        },
		created(){
			this.permission = this.checkPermission(this.userData,"Participant Management");
			this.listOrgan()
			this.listAllCountry()
		},
		methods: {
			length1000(e){
		          return String(e).substring(0,1000)
		    },
		    length250(e){
		          return String(e).substring(0,250)
		    }, 
		    length100(e){
		          return String(e).substring(0,100)
		    },
		    length50(e){
		          return String(e).substring(0,50)
		    }, 
		    length20(e){
		          return String(e).substring(0,20)
		    }, 
			validateNumber(event) {
				const charCode = String.fromCharCode(event.keyCode);
				if (!/[0-9]/.test(charCode) && charCode && event.keyCode != 13 ) {
					event.preventDefault();
					this.isNumber = false;
				}else{
					this.isNumber = true;
				}
			},
			listOrgan(){
	            this.$store
	            .dispatch('auth/Client_Organisation_List', {})
	            .then(response => {
					this.selectOr = response.data.data.filter(item => item.status === 1)
	            })
	            .catch((error) => {
	                console.log(error)
	            })
	        },
	        listAllCountry(){
              	this.$store
              	.dispatch('country/fetchCountry')
              	.then(response => {
                  	this.selectCountry = response.data.data.filter(item => item.status === 1)
              	})
              	.catch((error) => {
                	console.log(error)
              	})
          	},
			cancelAdd(){
				this.$router.push({ name: 'participant-management' })
			},
			saveAdd(){
				this.$refs.form_rel.validate().then(success => {
					if(success){
						let datas = {
							first_name: this.form.f_name,
							last_name: this.form.l_name,
							email: this.form.email,
							password: this.form.password,
							confirm_password: this.form.re_pass,
							date_of_birth: this.form.date,
							organisation: this.selectedOr ? this.selectedOr.id : null,
							position: this.form.designation,
							streets: this.form.street,
							salutation: this.selectedSalutation ? this.selectedSalutation.value : null,
							gender: this.selectedGender ? this.selectedGender.value : null,
							country: this.selectedCountry ? this.selectedCountry.id : null,
							contact_no: this.form.phone,
							city: this.form.city,
							postalcode: this.form.code,
							state_province: this.form.province,
							status: this.selectedStatus ? this.selectedStatus.value : null,
							fullname: this.form.full_name,
							remarks: this.form.remarks,
						}
						// console.log('datas: ', datas)
						this.$store
			                .dispatch('project/createPart', datas)
			                .then(response => {
			                    this.$toast({
			                        component: ToastificationContent,
			                        props: {
			                            title: this.form.f_name + ' Added!',
			                            variant: 'success',
			                        },
			                    },
			                    {
			                        position: 'top-center'
			                    })

			                	this.$router.push({ name: 'participant-management' })
			                	
			                })
			                .catch((error) => {
			                  	console.log(error)
			                  	this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                        	title: error.response.data.message,
			                        	variant: 'danger',
			                      	},
			                    },
			                    {
			                      position: 'top-center'
			                    })
			                }) 
					}
					else{
		            	 this.$toast({
	                        component: ToastificationContent,
	                        props: {
	                          title: 'One or more fields have an error. Please check and try again.',
	                          variant: 'danger',
	                        },
	                      },
	                      {
	                        position: 'top-center'
	                      }
	                    )
		            }
				})
			}
		},
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import "@/assets/scss/_ite_tab_table.scss";
</style>